export type ProductSource =
  | "CRYSTALLIZE"
  | "CRYSTALLIZE_SKOLE"
  | "CRYSTALLIZE_KUNNE";

export type PaymentSource = "INVOICE" | "VIPPS" | "TRIAL";

export interface WithUuid {
  uuid: UUID;
}

export const SUBJECT_CODES = [
  "ENG",
  "FSP",
  "KRO",
  "MAT",
  "NAT",
  "NOR",
  "SAF",
  "FYS",
  "GEO",
  "GFG",
  "HEL",
  "HIS",
  "KJE",
  "MED",
  "POL",
  "PSY",
  "REL",
  "KRL",
  "TVE",
  "INF",
  "SPA",
  "TYS",
  "FRA",
  "KHV",
  "MUS",
  "MHE",
  "SAK",
  "SOK",
  "SAG",
  "SOS",
  "POM",
  "PED",
] as const;

export type Subject = (typeof SUBJECT_CODES)[number];
