import { FetchError } from "ofetch";
import * as v from "valibot";

export function useCoreApi(basePath: `${"external" | "ecommerce"}/${string}`) {
  const { public: env } = useRuntimeConfig();

  const rootUrl = `/api/core/${basePath}`;
  const auth = useAuthorizeStore();
  const { time } = useServerTiming();

  const $fetch = useRequestFetch();

  return {
    rootUrl,

    endpoint(...parts: (string | undefined)[]) {
      if (parts.includes(undefined)) {
        throw new Error(`Endpoint had invalid parts: ${parts.join(", ")}`);
      }

      return [rootUrl, ...parts].join("/");
    },

    async $fetch<T>(
      url: string,
      init?: {
        method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
        body?: string;
        headers?: Record<string, string>;
        handle404asNull?: boolean;
      },
      direct = false,
    ): Promise<T | null> {
      let headers = (await auth.headers()) ?? {};

      headers = {
        ...headers,
        ...init?.headers,
      };

      return await time(`coreApi.fetch.${basePath}`, async () => {
        try {
          return await $fetch<T>(url, {
            headers,
            method: init?.method ?? "GET",
            body: init?.body,
          });
        } catch (e) {
          if (
            init?.handle404asNull &&
            e instanceof FetchError &&
            e.response?.status === 404
          ) {
            return null;
          }

          if (!direct) {
            // TODO: Consider feature flag for this
            const directUrl = basePath.startsWith("ecommerce")
              ? url.replace(/^\/api\/core\/ecommerce/, env.urls.core.eCommerce)
              : url.replace(/^\/api\/core\/external/, env.urls.core.external);

            return await this.$fetch<T>(directUrl, init, true);
          }

          let message = "Failed to fetch from core API";
          if (e instanceof FetchError) {
            const body = v.safeParse(v.string(), e.data);
            if (body.success) {
              message = body.output;
            }
          }
          handleError(e, message);
          throw e;
        }
      });
    },
  };
}
