import { normalizeClass } from "vue";

export function clsx(...classes: Parameters<typeof normalizeClass>) {
  return normalizeClass(classes);
}

type ClassDictionary = Record<string, any>;
type ClassArray = Array<ClassValue>;
type ClassValue =
  | ClassArray
  | ClassDictionary
  | string
  | number
  | boolean
  | null
  | undefined;

declare module "vue" {
  function normalizeClass(...inputs: ClassValue[]): string;
}
