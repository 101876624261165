import { datadogRum } from "@datadog/browser-rum";

export function handleError(
  error: unknown,
  message?: string,
  throwError = true,
) {
  if (!message) {
    if (typeof error === "string") {
      message = error;
    } else if (error instanceof Error) {
      message = error.message;
    } else {
      message = "Something went wrong";
    }
  }

  try {
    const data =
      !!error && typeof error === "object" && "data" in error && error.data;
    datadogRum.addError(error, {
      message,
      ...(data ?? {}),
      thrown: String(throwError),
    });
  } catch (e) {
    console.warn(e);
  }

  console.error(error);
  if (throwError) {
    throw error;
  }
}
