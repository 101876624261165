<script setup lang="ts">
const settings = useSettingsStore();
</script>

<template>
  <ul v-if="settings.some" class="social">
    <template v-for="provider in SOCIAL_PROVIDERS" :key="provider">
      <li v-if="settings.some[provider]">
        <UiSocialLink :provider="provider" :href="settings.some[provider]!" />
      </li>
    </template>
  </ul>
</template>

<style scoped lang="scss">
.social {
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    margin: 0 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
