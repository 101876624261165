import type { User } from "#auth-utils";
import {
  OIDC_DATA_KEY,
  TOKEN_DATA_KEY,
  userSessionState,
} from "@aschehoug/aschehoug-oidc-client";
import type { UserOrganization } from "~/utils/organization";

export const useAuthorizeStore = defineStore("authorize", () => {
  const logoutAM = useLogout();

  const sessionApi = useSessionApi();

  const { time } = useServerTiming();

  const hasConfirmedSelectedOrganization = useCookie(
    "__confirmedOrgSelection",
    {
      sameSite: "lax",
      secure: true,
      default: () => false,
    },
  );

  const user = ref<User>();
  const accessToken = useCookie("__accessToken");

  const loggedIn = computed(() => !!user.value);

  async function headers() {
    const client = useOidcClient();

    if (import.meta.client) {
      accessToken.value ||= client.getAccessToken();
    }

    if (!accessToken.value) {
      console.warn("No access token");
      return;
    }

    if (isExpired(accessToken.value)) {
      try {
        const state = await time("refreshAccessToken", () =>
          client.refreshAccessToken());
        if (state === userSessionState.hasActiveSession) {
          accessToken.value = client.getAccessToken();
        } else {
          logout(true);
          return;
        }
      } catch (e) {
        handleError(e, "", false);
        logout(true);
        return;
      }
    }

    return {
      Authorization: `Bearer ${accessToken.value}`,
    };
  }

  const queryClient = useQueryClient();

  function logout(local = false) {
    hasConfirmedSelectedOrganization.value = false;
    queryClient.removeQueries({
      queryKey: [user.value?.userId],
    });
    user.value = undefined;
    useCookie("__accessToken").value = null;
    accessToken.value = undefined;
    localStorage.removeItem(TOKEN_DATA_KEY);
    localStorage.removeItem(OIDC_DATA_KEY);

    useProductPreviewStore().clear();

    if (!local) {
      return logoutAM();
    } else {
      return navigateTo("/");
    }
  }

  async function fetchUserData() {
    if (!user.value || !user.value.hubspot) {
      await refreshUserData();
    }
  }

  async function refreshUserData() {
    user.value = await time("fetchUserData.user", async () =>
      $fetch<User>("/api/user", {
        headers: await headers(),
      }));

    const orgCount = user.value?.schoolOrganizations?.length ?? 0;
    if (orgCount <= 1) {
      hasConfirmedSelectedOrganization.value = true;
    }
  }

  async function updateActiveOrganization(newOrg: UserOrganization) {
    const orgNo = newOrg?.number;
    if (orgNo) {
      try {
        await time("setActiveOrganization", () =>
          sessionApi.setSessionAttributes({
            activeOrganization: orgNo,
            activeOrganizationName: newOrg?.name ?? "",
          }));

        await refreshUserData();
      } catch (error) {
        handleError(error, "Error updating active organization");
      }
    }
  }

  async function updateReferenceNumber(
    newValue: MaybeRef<string | undefined | null>,
  ) {
    const company = unref(user)?.hubspot;
    const referenceNumber = unref(newValue);
    if (
      company?.customerId &&
      referenceNumber &&
      referenceNumber !== company?.referenceNumber
    ) {
      const newData = await $fetch(
        `/api/hubspot/update-company/${company.customerId}`,
        {
          method: "PATCH",
          body: JSON.stringify({ referenceNumber }),
          headers: await headers(),
        },
      );

      if (user.value?.hubspot) {
        user.value.hubspot.referenceNumber = newData.referenceNumber;
      }
    }
  }

  const isTeacher = computed(
    () => user.value?.role?.includes("teacher") ?? false,
  );

  const isConsumer = computed(() => user.value?.source === "VIPPS");

  const canUseCart = computed(() => isTeacher.value || isConsumer.value);

  return {
    accessToken,
    isTeacher,
    isConsumer,
    canUseCart,
    loggedIn,
    user,
    hasConfirmedSelectedOrganization,
    logout,
    headers,
    fetchUserData,
    refreshUserData,
    updateReferenceNumber,

    async selectOrganization(newOrg: UserOrganization | undefined) {
      const old = user.value?.selectedOrganization;
      if (newOrg) {
        if (newOrg.number !== old?.number) {
          await updateActiveOrganization(newOrg);
        }

        hasConfirmedSelectedOrganization.value = true;
      }
    },
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthorizeStore, import.meta.hot));
}
