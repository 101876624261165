import type { Organization } from "~/types/core/ecommerce/organization";

/**
 * Get extended organization information (NSR data) for the currently selected organization.
 *
 * Will only run if the user is logged in and has selected an organization, and will stay fresh for 1 hour, since organization data is not likely to change often.
 */
export function useSelectedOrganization() {
  const api = useCoreApi("ecommerce/organizations");

  const auth = useAuthorizeStore();

  const orgNo = computed(() =>
    auth.isConsumer ? null : auth.user?.selectedOrganization?.number,
  );

  return useQuery({
    queryKey: ["organization", orgNo] as const,
    staleTime: 1000 * 60 * 60, // 1 hour
    enabled: !!unref(orgNo),
    async queryFn() {
      const raw = unref(orgNo);
      if (!raw) {
        return null;
      }

      const res = await api.$fetch<Organization>(api.endpoint(withPrefix(raw)));

      if (!res) {
        console.warn("Organization not found", orgNo.value);
      }

      return res ?? null;
    },
  });
}
