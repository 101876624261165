import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["content","component","dateTime","imageContent","image","item","location","paragraphCollection","product","subscriptionPlan","period","priceVariant","propertiesTable","itemRelations","gridRelations","richText","boolean","singleLine","videoContent","video","numeric","componentChoice","contentChunk","selection","file","GetArticleCards","GetArticleContent","GetArticleCounts","GetCanonicalPath","GetCartMetadata","GetCatalogue","GetPage","GetPodcastCards","GetProductFeed","GetSitemapInfo","GetTenant"],"search":[]}
export const GqlGetArticleCards = (...params) => useGql()('GetArticleCards', ...params)
export const GqlGetArticleContent = (...params) => useGql()('GetArticleContent', ...params)
export const GqlGetArticleCounts = (...params) => useGql()('GetArticleCounts', ...params)
export const GqlGetCanonicalPath = (...params) => useGql()('GetCanonicalPath', ...params)
export const GqlGetCartMetadata = (...params) => useGql()('GetCartMetadata', ...params)
export const GqlGetCatalogue = (...params) => useGql()('GetCatalogue', ...params)
export const GqlGetPage = (...params) => useGql()('GetPage', ...params)
export const GqlGetPodcastCards = (...params) => useGql()('GetPodcastCards', ...params)
export const GqlGetProductFeed = (...params) => useGql()('GetProductFeed', ...params)
export const GqlGetSitemapInfo = (...params) => useGql()('GetSitemapInfo', ...params)
export const GqlGetTenant = (...params) => useGql()('GetTenant', ...params)