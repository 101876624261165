export function useSessionApi() {
  const api = useCoreApi("external/frontend/session");

  return {
    async setSessionAttributes(attributes: Record<string, string>) {
      return await api.$fetch<Record<string, string>>(
        api.endpoint("attributes"),
        {
          method: "PATCH",
          body: JSON.stringify(attributes),
        },
      );
    },
  };
}
