export const SOCIAL_PROVIDERS = [
  "facebook",
  "instagram",
  "linkedin",
  "x",
  "tiktok",
  "youtube",
] as const;

export type SocialProvider = (typeof SOCIAL_PROVIDERS)[number];
