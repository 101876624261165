import { addMonths } from "date-fns";
import * as v from "valibot";

const previewsSchema = v.pipe(
  v.unknown(),
  v.transform((it) => (typeof it === "string" ? JSON.parse(it) : it)),
  v.record(
    v.string(),
    v.pipe(
      v.union([v.string(), v.number()]),
      v.transform((input) => new Date(input)),
    ),
  ),
);

const PREVIEW_MONTHS = 3;
const METADATA_KEY = "product-previews";

export const serializer = {
  deserialize: (value: string) => {
    const parsed = v.safeParse(previewsSchema, value);
    if (parsed.success) {
      return new Map(Object.entries(parsed.output));
    }
    handleError(parsed.issues, "Error parsing product previews", false);
    return null;
  },
  serialize: (value: Map<string, Date>) =>
    JSON.stringify(Object.fromEntries(value)),
};

export const useProductPreviewStore = defineStore("product-preview", () => {
  const previews = ref<Map<string, Date> | undefined>(undefined);

  const api = useMetadataApi();

  async function fetchPreviews() {
    try {
      const data = await api.getUserMetaData(METADATA_KEY);

      if (data?.value) {
        const parsed = serializer.deserialize(data.value);
        if (parsed) {
          previews.value = parsed;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function enablePreview(sku: string) {
    if (!previews.value) {
      await fetchPreviews();
    }

    previews.value ??= new Map();

    if (previews.value.has(sku)) {
      return false;
    }

    previews.value.set(sku, addMonths(new Date(), PREVIEW_MONTHS));

    try {
      await api.createOrUpdateMetadata(
        METADATA_KEY,
        serializer.serialize(previews.value),
      );

      return true;
    } catch (e) {
      console.error(e);
      previews.value?.delete(sku);

      return false;
    }
  }

  return {
    clear: () => previews.value?.clear(),
    previews,
    fetchPreviews,
    enablePreview,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useProductPreviewStore, import.meta.hot),
  );
}
