import type { Crystallize } from "~/crystallize/spec";
import type { Document, Identifiable } from "~/types/document";
import type { Episode } from "~/types/podcasts";
import type { Level } from "~/utils/constants";
import type { Slug } from "~/utils/slug";

export interface ArticleType extends Document {
  variant: Crystallize.ArticleVariant;
  colorTheme: Crystallize.ArticleColorTheme;
  thumbnailImage?: ImageWithAltType;
  intro?: string;
  author?: string | null;
  photoCredit?: string | null;
  hubspotForm?: string;
  body?: string[];
  postCtaBody?: string[];
  extraBox?: string;
  footnotes?: string;
  podcast?: string;
  podcastEpisodes?: Array<Episode>;
  inlineCtas?: CTAContent[];
  ctaLicence?: boolean;
  ctaContent?: Array<CTAContent>;
  showRelatedArticles: boolean;
  relatedArticles: Array<ArticleCard>;
  accordion?: Array<AccordionContent>;
  contentType: "article";
}

export const CTA_LINKS = [
  "modus",
  "aunivers-barneskole",
  "aunivers-ungdomsskole",
  "produktkatalog",
  "digitalt-supplement",
] as const;

export type CtaLink = (typeof CTA_LINKS)[number];

export interface CTAContent {
  title?: string;
  content?: string;
  link?: string;
  label?: string;
  links: CtaLink[];
}

export interface AccordionContent {
  groupTitle?: string;
  title?: string;
  content?: string;
  img?: ImageWithAltType;
}

export interface ArticleCard extends Identifiable {
  levels: Level[];
  subject?: string[];
  colorTheme: Crystallize.ArticleColorTheme;
  slug: Slug;
  title: string;
  cover?: ImageWithAltType;
  thumbnailImage?: ImageWithAltType;
  theme?: string[];
}

export interface ImageWithAltType {
  img?: string;
  alt?: string;
}

export interface HighlightedComponentType {
  title?: string;
  articles: ArticleCard[];
}
