import type { ProductSource } from "~/types/core/common";
import type {
  CheckoutRequest,
  CheckoutResponse,
} from "~/types/core/ecommerce/checkout";

export function useCheckoutApi() {
  const api = useCoreApi("ecommerce/checkout");
  return {
    async initiateCheckoutSession(
      productSource: ProductSource,
      request: CheckoutRequest,
      redirectUrl?: string,
    ) {
      let url = `${api.rootUrl}?productSource=${productSource}`;

      if (redirectUrl) {
        url += `&redirectUrl=${encodeURIComponent(redirectUrl)}`;
      }

      return await api.$fetch<CheckoutResponse>(url, {
        method: "POST",
        body: JSON.stringify(request),
      });
    },

    async getCheckoutSession(sessionId: UUID) {
      return await api.$fetch<CheckoutResponse>(api.endpoint(sessionId));
    },
  };
}
