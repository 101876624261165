import validate from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.3_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_cegceq4jqas5xvrpeyogx453pi/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/middleware/auth.global.ts";
import manifest_45route_45rule from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.3_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_cegceq4jqas5xvrpeyogx453pi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-auth": () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/middleware/check-auth.ts")
}