import type { RouteLocationNormalized } from "vue-router";

interface Seo {
  title?: string;
  description?: string;
}

type Some = Partial<Record<SocialProvider, string> & { image: string }>;

export const useSettingsStore = defineStore("settings", () => {
  const planB = ref(false);
  const vipps = ref(false);
  const updateSubscription = ref(false);
  const seo = ref<Seo | null>(null);
  const some = ref<Some | null>(null);

  async function fetchSettings(route?: RouteLocationNormalized) {
    if (seo.value !== null && some.value !== null) {
      return;
    }

    const { data } = await useAsyncGql("GetPage", {
      language: "no-nb",
      path: "/innstillinger",
      version: useCrystallizeVersion(route),
    });

    const components = data.value?.catalogue?.components ?? [];

    for (const component of components) {
      switch (component.id) {
        case "plan-b":
          planB.value = getBoolean(component);
          break;
        case "vipps":
          vipps.value = getBoolean(component);
          break;
        case "update-subscription":
          updateSubscription.value = getBoolean(component);
          break;
        case "some": {
          const someChunks = getSingleChunks(component);
          const newSome: Some = {};
          for (const chunk of someChunks) {
            if (!chunk.content) {
              continue;
            }

            const text = getSingleLine(chunk);

            switch (chunk.id) {
              case "facebook-link":
                newSome.facebook = text;
                break;
              case "instagram-link":
                newSome.instagram = text;
                break;
              case "linkedin-link":
                newSome.linkedin = text;
                break;
              case "twitter-link":
                newSome.x = text;
                break;
              case "tiktok-link":
                newSome.tiktok = text;
                break;
              case "youtube":
                newSome.youtube = text;
                break;
              case "fallbackbilde-for-some":
                newSome.image = getSingleImage(chunk, 500)?.img;
                break;
            }
          }
          some.value = newSome;
          break;
        }
        case "fallback-seo": {
          const fallbackSeoChunks = getSingleChunks(component);
          const newSeo: Seo = {};
          for (const chunk of fallbackSeoChunks) {
            if (!chunk.content) {
              continue;
            }

            const text = getSingleLine(chunk);
            switch (chunk.id) {
              case "generisk-seo-tittel":
                newSeo.title = text;
                break;
              case "generisk-seo-beskrivelse":
                newSeo.description = text;
                break;
            }
          }
          seo.value = newSeo;
          break;
        }
      }
    }
  }

  return {
    planB,
    vipps,
    updateSubscription,
    seo,
    some,
    fetchSettings,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot));
}
