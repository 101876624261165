export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T;

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export function truthyAndDistinct<T>(
  value: T,
  index: number,
  array: T[],
): value is Truthy<T> {
  return truthy(value) && array.indexOf(value) === index;
}

export function distinct<T>(value: T, index: number, array: T[]): boolean {
  return array.indexOf(value) === index;
}

export function isString(value: unknown): value is string {
  return typeof value === "string";
}

export function isNonBlankString(value: unknown): value is string {
  return typeof value === "string" && value.trim().length > 0;
}

export function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === "boolean";
}

export function handleFilterChange(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target.form) {
    const formData = new FormData(target.form);

    // @ts-expect-error TS complains that FormData may contain files, but they will be coerced into strings by JS, so we are fine.
    const query = new URLSearchParams(formData).toString();

    navigateTo(query ? `?${query}` : useRoute().path, {
      replace: true,
    });
  }
}
