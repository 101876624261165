import * as v from "valibot";

export function getFirstLetter(text: string | undefined | null) {
  if (typeof text === "string" && text.length > 0) {
    return text.charAt(0).toLowerCase();
  }
  return "a";
}

export const nonEmptyString = v.pipe(v.string(), v.minLength(1));
