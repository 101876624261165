export interface Slug {
  full: string;
  short: string;
  custom?: string;
}

/**
 * Takes a path and returns an object with it + the "short slug" at the end of it, meaning whatever comes after the last delimiter.
 *
 * @param path - A full path, i.e. /i/am/a/path
 * @param delimiter - What to split the string on when calculating the slug, defaults to a slash.
 */
export function pathToSlug(path: string, delimiter = "/"): Slug {
  return {
    full: path,
    short: path.split(delimiter).pop() ?? "",
  };
}

/**
 * Converts some text to a URL-friendly variant, i.e. 'I am a text æ ø å' is converted to 'i-am-a-text-ae-oe-a'
 *
 * @param text - Text to convert to a slug.
 */
export function slugify(text: string) {
  return text
    .toLowerCase()
    .replace(/æ/g, "ae")
    .replace(/å/g, "a") // Would be more precise to use 'aa', but Crystallize converts to a single 'a', so we emulate that logic
    .replace(/ø/g, "oe")
    .replace(/[^a-z ]/gi, "")
    .trim()
    .replace(/ /g, "-");
}

/**
 * Use in .filter or .find like so: [...items].find(bySlug("some-slug"))
 *
 * @param slug - Slug (either short, long or custom) you want to match items against.
 */
export function bySlug(slug: string) {
  return (item: { slug: Slug }) => Object.values(item.slug).includes(slug);
}
