interface TimingEntry {
  duration: number;
  description?: string;
}

/**
 * Composable for working with the Server-Timing header. Can be used to time specific parts of your request, such as how long it takes to fetch data from an external API.
 */
export function useServerTiming() {
  const timings = useState<Record<string, TimingEntry[]>>(
    "serverTiming.timings",
    () => ({}),
  );

  return {
    /**
     * Get the raw Server-Timing header value, which will format all your timing entries correctly so that they show up in the browser's devtools.
     *
     * @param clear - Whether to clear the timing data after getting the header value. Defaults to `true`, as this what you most often want to do. Set to false if you need to log the header or similar.
     */
    getHeader(clear = true) {
      const headerValue = Object.entries(timings.value)
        .map(([key, entries]) => {
          const dur = entries
            .reduce((acc, entry) => acc + entry.duration, 0)
            .toFixed(1);

          const desc = entries
            .map((entry) => entry.description)
            .filter(Boolean)
            .join(" & ");

          return [
            key.replaceAll(/([: @=;,])/g, "_"),
            desc ? `desc=${JSON.stringify(desc)}` : null,
            `dur=${dur}`,
          ]
            .filter(Boolean)
            .join(";");
        })
        .join(",");

      if (clear) {
        timings.value = {};
      }

      return headerValue;
    },

    /**
     * Time a function or promise and store the result in the `timings` state.
     *
     * @param key - The key to store the timing under. Can be a string or an object with a `key` and a `description` property.
     * @param fn - The function or promise to time.
     */
    async time<T>(
      key: string | { key: string; description: string },
      fn: Promise<T> | (() => T | Promise<T>),
    ): Promise<T> {
      const start = performance.now();
      const promise = typeof fn === "function" ? fn() : fn;
      const result = await promise;
      const duration = performance.now() - start;
      const resolvedKey = typeof key === "string" ? key : key.key;

      timings.value[resolvedKey] ??= [];
      timings.value[resolvedKey]?.push(
        typeof key === "string"
          ? { duration }
          : { duration, description: key.description },
      );

      return result;
    },
  };
}
