import type { InjectionKey, Ref } from "vue";
import type { ArticleType } from "~/types/articles";
import type { Podcast } from "~/types/podcasts";
import type { SingleProduct } from "~/types/single-product";
import type { Packet } from "~/types/universe";

export type ToastFn = (
  message:
    | string
    | {
      message?: string;
      target?: string;
      duration?: number;
      persistent?: boolean;
      buttons?: Array<{ text: string; onClick: () => void }>;
      onClose?: () => void;
    },
) => void;

export const keys = {
  toast: "ksToast" as unknown as InjectionKey<{
    success: ToastFn;
    error: ToastFn;
    info: ToastFn;
  }>,
  currentArticle: Symbol("currentArticle") as InjectionKey<
    ArticleType | Podcast
  >,
  currentPacket: Symbol("currentPacket") as InjectionKey<ComputedRef<Packet>>,
  currentProduct: Symbol("currentProduct") as InjectionKey<
    Ref<SingleProduct | undefined>
  >,
};
