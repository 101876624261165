export function useLogout() {
  const { public: env } = useRuntimeConfig();

  const { clear } = useUserSession();

  const { origin } = useRequestURL();

  return async function logout() {
    await clear();

    return navigateTo(
      {
        path: `${env.urls.core.external}/lokus/am/frontend/logout`,
        query: {
          redirect: origin,
        },
      },
      {
        external: true,
      },
    );
  };
}
