<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";

useHead({
  htmlAttrs: {
    lang: "no",
    prefix: "og: https://ogp.me/ns#",
  },
});

const error = useError();

console.error(error.value);

function handleError(event: Event) {
  // If the error does not prevent JS from being loaded, this will let us clear the error before navigating.
  // If JS is not loaded, the link will work as a normal link.
  const url = new URL((event.target as HTMLLinkElement).href);
  return clearError({
    redirect: url.pathname,
  });
}
</script>

<template>
  <NuxtLayout>
    <LayoutSkipTo />
    <LayoutHeader />
    <main class="error">
      <div class="error__hero">
        <h1 class="headline" aria-label="En feil med applikasjonen oppstått">
          Å nei :(
        </h1>
        <p v-if="error && 'statusCode' in error && error.statusCode === 404">
          Vi finner ikke siden du leter etter.
        </p>
        <p v-else>
          Det har skjedd en feil.
        </p>
        <a href="/" @click.prevent="handleError">
          <KsIcon :icon="faLongArrowRight" :scale="1.4" /> Til forsiden
        </a>
        <a href="/produktkatalog" @click.prevent="handleError">
          <KsIcon :icon="faLongArrowRight" :scale="1.4" />
          Til produktkatalog
        </a>
        <img
          src="https://media.crystallize.com/au-skole-dev/24/1/2/1/error-group.png"
          aria-hidden="true"
          alt=""
        />
      </div>
      <UiStripesArticles
        background="yellow-light-green"
        :show-all="true"
        :range="[0, 3]"
        title="Siste artikler"
      />
    </main>
    <LayoutFooter />
  </NuxtLayout>
</template>

<style scoped lang="scss">
.error {
  background-color: $green-30;
  margin: -8rem 0 0 0;

  &__hero {
    max-width: $max-width-article;
    margin: 0 auto;
    color: theme("colors.green.30");
    padding: 0;

    h1 {
      padding: 12rem 0 2rem 0;
      font-size: 5.625rem;
      font-weight: 400;
    }

    p {
      font-size: 2.1rem;
      font-weight: 400;
      margin-bottom: 4rem;
    }

    img {
      margin: 0;
      padding: 0;
      transform: translateY(0.21rem);
    }

    a {
      font-size: 1.4rem;
      color: theme("colors.green.30");
      display: flex;
      gap: 1rem;
      align-items: center;
      margin: 1rem 0;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;

      &:hover {
        color: theme("colors.yellow.30");
        transform: translateX(0.5rem);
      }
    }
  }
}
</style>
