import type { ImageWithAltType } from "@/types/articles";
import type { Crystallize } from "~/crystallize/spec";
import type { Product, ProductVariant } from "~/types/product-catalogue";
import type { Packet } from "~/types/universe";
import type { BiblioFormat, LevelFilter } from "~/utils/constants";

export const SINGLE_PRODUCT_TEMPLATES = [
  "standard",
  "pluss",
  "grunnskole",
] as const satisfies ReadonlyArray<Crystallize.SingleProductTemplate>;

export const SINGLE_PRODUCT_THEMES = [
  "green",
  "blue",
  "purple",
] as const satisfies ReadonlyArray<Crystallize.SingleProductColorTheme>;

export interface SingleProduct
  extends Product<"single-product", SingleProductVariant> {
  template: Crystallize.SingleProductTemplate;
  theme: Crystallize.SingleProductColorTheme;
  biblioFormats: BiblioFormat[];
  intro?: string;
  description?: string;
  heroCards?: HeroCards;
  selfDefinedColorVariant?: boolean;
  footerMascot?: string;
  illustration?: ImageWithAltType;
  relatedProducts: Packet[];
  pendelCta: boolean;
  levelFilter?: LevelFilter;
}

export const LANGUAGES = ["nob", "nno", "eng", "ger", "no2"] as const;

export type Language = (typeof LANGUAGES)[number];

export interface SingleProductVariant extends ProductVariant<"single-product"> {
  isPurchasable: boolean;
  intro?: string;
  authors?: string;
  release?: Date;
  pages?: string;
  textColor?: string;
  mainColor?: string;
  languages?: Language[];
  browsableLink?: string;
  previewLink?: string;
  outOfPrint?: boolean;
}

export interface HeroCardContent {
  text?: string;
  backgroundColor?: string;
  textColor?: string;
}

export interface HeroCards {
  content: HeroCardContent[];
  mascotImages: ImageWithAltType[];
  colorTheme: string;
}
