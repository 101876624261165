import type { CookieOptions } from "#app";
import type { RouteLocationRaw } from "#vue-router";
import type { PublicRuntimeConfig } from "nuxt/schema";
import { jwtDecode } from "jwt-decode";

export function isExpired(token: string | undefined) {
  if (!token) {
    return true;
  }

  const decoded = jwtDecode(token);
  const exp = Number(decoded.exp) * 1000;
  return exp < Date.now();
}

export const REDIRECT_COOKIE_NAME = "nuxt-redirect";

export const AUTH_COOKIE_OPTIONS = {
  secure: true,
  httpOnly: true,
  sameSite: "lax",
  maxAge: 60 * 10, // 10 minutes
} as const satisfies CookieOptions;

export const IDSP_MAPPING = {
  feide: "skoleFeide",
  vipps: "vipps_kunne_oidc_tree",
} as const satisfies Record<string, string>;

export type Idsp = keyof typeof IDSP_MAPPING;

export function getAuthUrl(
  idsp: Idsp,
  env: PublicRuntimeConfig,
): RouteLocationRaw {
  return {
    path: `${env.urls.core.am}/am/XUI/`,
    query: {
      service: IDSP_MAPPING[idsp],
      goto: env.oauth.redirectURL,
      gotoOnFail: `${env.urls.core.id}/feil`,
      realm: "/",
    },
  };
}

export function getAmUrl(
  endpoint: "authorize" | "access_token",
  env: PublicRuntimeConfig,
) {
  return `${env.urls.core.am}/am/oauth2/realms/root/${endpoint}`;
}
