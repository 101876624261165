import type { UseSeoMetaInput } from "@unhead/vue";
import type { Document } from "~/types/document";

const NAME = "Aschehoug";

export default function useAddSeo(
  document?: Document,
  extra?: UseSeoMetaInput,
): UseSeoMetaInput {
  const settings = useSettingsStore();
  const route = useRoute();
  const {
    public: { siteUrl },
  } = useRuntimeConfig();

  const ogTitle = withCompanyPrefix(
    document?.seo?.ogTitle || settings.seo?.title,
  );
  const ogDescription =
    document?.seo?.ogDescription || settings.seo?.description;
  const ogImage = document?.cover?.img || settings.some?.image;

  return {
    title: withCompanyPrefix(document?.seo?.title || settings.seo?.title),
    ogTitle,
    description: document?.seo?.description || settings.seo?.description,
    ogDescription,
    ogLocale: "no",
    ogSiteName: NAME,
    ogUrl: siteUrl + route.path,
    ogImage,
    twitterCard: "summary_large_image",
    twitterTitle: ogTitle,
    twitterDescription: ogDescription,
    twitterImage: ogImage,
    twitterImageAlt: document?.cover
      ? document?.cover?.alt
      : settings.seo?.title,
    twitterSite: `@${NAME}`,
    ...extra,
  };
}

function withCompanyPrefix(title: string | undefined | null) {
  return [NAME, title].filter(truthy).join(" - ");
}
