import * as v from "valibot";

export const orgNoSchema = v.pipe(
  v.union([v.string(), v.number()]),
  v.transform(String),
  v.string(),
  v.regex(/^(?:NO)?(?:\d{9}|U\d{8})$/),
  v.brand("OrganizationNumber"),
);

export type OrganizationNumber = v.InferOutput<typeof orgNoSchema>;

export type PrefixedOrganizationNumber = OrganizationNumber &
  v.Brand<"PrefixedOrganizationNumber">;

export type UnprefixedOrganizationNumber = OrganizationNumber &
  v.Brand<"UnprefixedOrganizationNumber">;

export function withPrefix(
  input: OrganizationNumber,
): PrefixedOrganizationNumber {
  return (
    input.startsWith("NO") ? input : `NO${input}`
  ) as PrefixedOrganizationNumber;
}

export function withoutPrefix(
  input: OrganizationNumber,
): UnprefixedOrganizationNumber {
  return input.replace(/^NO/, "") as UnprefixedOrganizationNumber;
}

export function isValidOrganizationNumber(
  input: unknown,
): input is OrganizationNumber {
  return v.is(orgNoSchema, input);
}
