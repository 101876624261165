<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
import {
  faEnvelope,
  faHeadphones,
  faReceipt,
  faShieldCheck,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";

const currentYear = new Date().getFullYear();

const links = [
  {
    text: "Kundeservice",
    to: "/kundeservice",
    icon: faHeadphones,
  },
  {
    text: "Brukerinvolvering",
    to: "/brukerinvolvering",
    icon: faUsers,
  },
  {
    text: "Nyhetsbrev",
    to: "/nyhetsbrev",
    icon: faEnvelope,
  },
  {
    text: "Kjøpsbetingelser",
    to: "/kjopsbetingelser",
    icon: faReceipt,
  },
  {
    text: "Personvern og cookies",
    to: "/personvern",
    icon: faShieldCheck,
  },
];
</script>

<template>
  <footer class="footer flex w-screen flex-col items-center gap-3 bg-green-50 pb-8 pt-1 text-white tablet:pt-12">
    <nav class="mx-4 flex w-full max-w-content flex-col justify-between border-b border-b-green-30 pb-2 pt-8 tablet:mx-auto tablet:flex-row">
      <ul class="flex flex-[6] flex-col gap-7 px-3 py-2 tablet:flex-row tablet:flex-wrap tablet:gap-10 tablet:px-0 tablet:py-4">
        <li v-for="link in links" :key="link.to">
          <NuxtLink :to="link.to" class="flex gap-4 no-underline sm:gap-3">
            <KsIcon :icon="link.icon" :scale="1.2" />
            {{ link.text }}
          </NuxtLink>
        </li>
      </ul>
      <LayoutSocial class="hidden flex-1 items-center justify-end tablet:flex" />
    </nav>
    <div class="mx-8 my-4 flex w-full max-w-content items-center justify-between px-4 tablet:justify-center">
      <p>Aschehoug&nbsp;{{ currentYear }}</p>
      <LayoutSocial class="flex items-center justify-end tablet:hidden" />
    </div>
  </footer>
</template>
