import * as v from "valibot";

export const isbnSchema = v.pipe(
  v.string(),
  v.check(isValidIsbn10or13),
  v.brand("ISBN"),
);

export type ISBN = v.InferOutput<typeof isbnSchema>;

export function isValidIsbn(input: unknown): input is ISBN {
  return v.is(isbnSchema, input);
}

function isValidIsbn10or13(isbn: string) {
  // Remove any hyphens and spaces
  isbn = isbn.replace(/[-\s]/g, "");

  if (isbn.length === 10) {
    return isValidIsbn10(isbn);
  }

  if (isbn.length === 13) {
    return isValidIsbn13(isbn);
  }

  return false;
}

function isValidIsbn10(isbn: string): boolean {
  // Ensure only digits or last character 'X'
  if (!/^\d{9}[\dX]$/.test(isbn)) {
    return false;
  }

  const sum = [...isbn].reduce((sum, char, i) => {
    const multiplier = i === 9 && char === "X" ? 10 : Number.parseInt(char);
    return sum + (i + 1) * multiplier;
  }, 0);

  // Valid if sum is divisible by 11
  return sum % 11 === 0;
}

function isValidIsbn13(isbn: string): boolean {
  // Ensure only digits
  if (!/^\d{13}$/.test(isbn)) {
    return false;
  }

  const sum = [...isbn].slice(0, 12).reduce((sum, char, i) => {
    const multiplier = i % 2 === 0 ? 1 : 3;
    return sum + Number.parseInt(char) * multiplier;
  }, 0);

  // Calculate the check digit
  const checkDigit = (10 - (sum % 10)) % 10;

  // Compare with the last digit
  return checkDigit === Number.parseInt(isbn[12]!);
}
