import type { GetArticleCountsQuery } from "#gql";
import type { Crystallize } from "~/crystallize/spec";
import type {
  AccordionContent,
  ArticleCard,
  ArticleType,
  CTAContent,
} from "~/types/articles";
import { CTA_LINKS } from "~/types/articles";
import type { Episode } from "~/types/podcasts";
import type { ItemRelation, Page } from "~/utils/crystallize";

export function toArticle(item: Page | ItemRelation): ArticleType {
  const article = createDocument<ArticleType>(item, {
    intro: "",
    body: [],
    colorTheme: "green-seagreen",
    variant: "simple",
    hubspotForm: "",
    podcast: "",
    podcastEpisodes: [],
    showRelatedArticles: false,
    relatedArticles: [],
    accordion: [],
    ctaContent: [],
    contentType: "article",
  });

  if (item.components) {
    for (const component of item.components) {
      if (!component.content) {
        continue;
      }

      const id = component.id as Crystallize.ComponentId<
        "artikkel-standard" | "informasjonsside"
      >;

      switch (id) {
        case "tittel":
          article.title = getSingleLine(component);
          article.slug.custom = slugify(article.title);
          break;

        case "publiseringsdato":
          article.publishedAt = getDatetime(component);
          break;

        case "ingress":
          article.intro = getRichText(component);
          break;

        case "ekstra-informasjon": {
          const chunks = getSingleChunks(component);

          for (const chunk of chunks) {
            if (!chunk.content) {
              continue;
            }

            const id = chunk.id as Crystallize.ChunkId<
              "artikkel-standard",
              "ekstra-informasjon"
            >;

            switch (id) {
              case "trinn":
                article.levels = getLevels(chunk);
                break;
              case "tema":
                article.theme = getSelections(chunk, "value");
                break;
              case "fag":
                article.subject = getSelections(chunk, "value");
                break;
            }
          }
          break;
        }

        case "fargetema": {
          const theme = getSelections(component)[0];
          if (theme) {
            article.colorTheme = theme as Crystallize.ArticleColorTheme;
          }
          break;
        }

        case "forfatter": {
          const selectedComponent = getComponentChoice(component);
          if (selectedComponent.type === "singleLine") {
            article.author = getSingleLine(selectedComponent);
          }
          if (selectedComponent.type === "itemRelations") {
            article.author = getItemRelations(selectedComponent)[0]?.name;
          }
          break;
        }

        case "byline-foto": {
          const selectedCredits = getComponentChoice(component);
          if (selectedCredits.type === "singleLine") {
            article.photoCredit = getSingleLine(selectedCredits);
          }
          if (selectedCredits.type === "itemRelations") {
            article.photoCredit = getItemRelations(selectedCredits)[0]?.name;
          }
          break;
        }

        case "coverbilde":
          article.cover = getSingleImage(component, 1366);
          break;

        case "thumbnailbilde":
          article.thumbnailImage = getSingleImage(component);
          break;

        case "hubspot-skjema":
          article.hubspotForm = getSingleLine(component);
          break;

        case "seo": {
          const seoChunks = getSingleChunks(component);
          for (const chunk of seoChunks) {
            if (!chunk.content) {
              continue;
            }

            const id = chunk.id as Crystallize.ChunkId<
              "informasjonsside",
              "seo"
            >;

            if (id === "sidetittel") {
              article.seo.title = getSingleLine(chunk);
            } else if (id === "beskrivelse") {
              article.seo.description = getSingleLine(chunk);
            }
          }
          break;
        }

        case "some-metadata": {
          const soMeChunks = getSingleChunks(component);
          for (const chunk of soMeChunks) {
            if (!chunk.content) {
              continue;
            }

            const id = chunk.id as Crystallize.ChunkId<
              "informasjonsside",
              "some-metadata"
            >;

            switch (id) {
              case "tittel":
                article.seo.ogTitle = getSingleLine(chunk);
                break;
              case "beskrivelse":
                article.seo.ogDescription = getSingleLine(chunk);
                break;
              case "twitter-card-tittel":
                article.seo.twitterTitle = getSingleLine(chunk);
                break;
              case "twitter-card-beskrivelse":
                article.seo.twitterDescription = getSingleLine(chunk);
                break;
            }
          }
          break;
        }

        case "trekkspill": {
          const accordionChunks = getRepeatableChunks(component);
          article.accordion = accordionChunks.map((accordionItem) => {
            const accordionContent: AccordionContent = {};
            for (const content of accordionItem) {
              if (!content.content) {
                continue;
              }

              const id = content.id as Crystallize.ChunkId<
                "informasjonsside",
                "trekkspill"
              >;

              switch (id) {
                case "gruppetittel":
                  accordionContent.groupTitle = getSingleLine(content);
                  break;
                case "tittel":
                  accordionContent.title = getSingleLine(content);
                  break;
                case "tekst":
                  accordionContent.content = getRichText(content);
                  break;
                case "bilde":
                  accordionContent.img = getSingleImage(content);
                  break;
              }
            }

            return accordionContent;
          });
          break;
        }

        case "cta": {
          const ctas = getRepeatableChunks(component);
          if (ctas.length > 0) {
            article.ctaLicence = true;
            article.ctaContent = ctas.map(toCta);
          }
          break;
        }

        case "inline-cta": {
          const ctas = getRepeatableChunks(component);
          if (ctas.length > 0) {
            article.inlineCtas = ctas.map(toCta);
          }
          break;
        }

        case "design":
          article.variant = getSelections(
            component,
          )[0] as Crystallize.ArticleVariant;
          break;

        case "tekst":
          article.body = getParagraphCollection(component);
          break;

        case "post-cta-text":
          article.postCtaBody = getParagraphCollection(component);
          break;

        case "innhold":
          article.body = getParagraphCollection(component);
          break;

        case "faktaboks":
          article.extraBox = getRichText(component);
          break;

        case "fotnoter":
          article.footnotes = getRichText(component);
          break;

        case "podkast":
          article.podcast = getSingleLine(component);
          break;

        case "podkastepisoder": {
          const episodes = getItemRelations(component);
          article.podcastEpisodes = episodes.map((item) => {
            const episode: Episode = {
              id: item.id,
              date: new Date(),
            };

            if (item.components) {
              for (const c of item.components) {
                if (!c.content) {
                  continue;
                }

                const id = c.id as Crystallize.ComponentId<
                  Crystallize.ItemRelationId<
                    "artikkel-standard",
                    "podkastepisoder"
                  >
                >;

                switch (id) {
                  case "tittel":
                    episode.title = getSingleLine(c);
                    break;
                  case "published":
                    episode.date = getDatetime(c);
                    break;
                  case "beskrivelse":
                    episode.description = getRichText(c);
                    break;
                  case "embed":
                    episode.embed = getSingleLine(c);
                    break;
                  case "transkribering":
                    episode.transcription = getRichText(c);
                    break;
                  case "spotify-link":
                    episode.spotifyLink = getSingleLine(c);
                    break;
                }
              }
            }

            return episode;
          });

          article.podcastEpisodes.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          });
          break;
        }

        case "cta-provelisens":
          article.ctaLicence = getBoolean(component);
          break;

        case "relaterte-artikler":
          article.showRelatedArticles = getBoolean(component);
          break;

        case "related-articles": {
          article.relatedArticles = getItemRelations(component)
            .map(toArticle)
            .map(toArticleCard);
          break;
        }
      }
    }
  }

  if (!article.levels?.length) {
    article.levels = ["1-7", "8-10", "vgs"];
  }

  return article;
}

export function toReadCounts(data: GetArticleCountsQuery) {
  const counts: Record<string, number> = {};

  const content = data.catalogue?.component?.content;

  if (content && "sections" in content && content.sections) {
    for (const section of content.sections) {
      if (section.properties) {
        for (const item of section.properties) {
          counts[item.key] = Number(item.value);
        }
      }
    }
  }

  return counts;
}

export function toArticleCard(article: ArticleType): ArticleCard {
  return {
    id: article.id,
    levels: article.levels,
    subject: article?.subject,
    colorTheme: article?.colorTheme,
    slug: article.slug,
    title: article.title,
    cover: article?.cover,
    thumbnailImage: article?.thumbnailImage,
    theme: article?.theme,
  };
}

function toCta(ctaChunks: ReturnType<typeof getSingleChunks>): CTAContent {
  const ctaData: CTAContent = {
    links: [],
  };
  for (const chunk of ctaChunks) {
    if (!chunk.content) {
      continue;
    }

    const id = chunk.id as Crystallize.ChunkId<
      "artikkel-standard",
      "cta" | "inline-cta"
    >;

    switch (id) {
      case "tittel":
        ctaData.title = getSingleLine(chunk);
        break;
      case "tekst":
        ctaData.content = getRichText(chunk);
        break;
      case "lenke":
        ctaData.link = getSingleLine(chunk);
        break;
      case "lenke-etikett":
        ctaData.label = getSingleLine(chunk);
        break;
      case "links": {
        const links = getSelections(chunk, "key");
        ctaData.links.push(
          ...links
            .map((key) => CTA_LINKS.find((it) => key === it))
            .filter(nonNullable),
        );
        break;
      }
    }
  }

  // We only want unique links
  ctaData.links = ctaData.links.filter(distinct);

  return ctaData;
}
