export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = handler.bind(null, "errorHandler");

  nuxtApp.hook("app:error", handler.bind(null, "app:error"));

  nuxtApp.hook("vue:error", handler.bind(null, "vue:error"));
});

function handler(
  source: "app:error" | "vue:error" | "errorHandler",
  error: unknown,
) {
  if (error) {
    if (import.meta.client) {
      const shouldThrow =
        !!error &&
        typeof error === "object" &&
        "fatal" in error &&
        !!error.fatal;

      handleError(error, source, shouldThrow);
    } else {
      console.error(error);
    }
    clearError();
  }
}
