import {
  AschehougOidcClient,
  RequestScopes,
} from "@aschehoug/aschehoug-oidc-client";

let client: AschehougOidcClient;

export default function useOidcClient() {
  if (!client) {
    const { public: env } = useRuntimeConfig();
    const { origin } = useRequestURL();
    client = new AschehougOidcClient({
      authority: env.urls.core.am,
      client_id: "ehandel_client",
      redirect_uri: `${origin}/minside`,
      redirect_feide_uri: env.oauth.redirectURL,
      // @ts-expect-error Erronous types in library
      scope: RequestScopes.openIdProfileEmail,
      post_logout_redirect_uri: origin,
      response_type: "code",
      metadata: {
        authorization_endpoint: getAmUrl("authorize", env),
      },
      base_uri_am: env.urls.core.am,
      selfService_api_url: env.urls.core.api,
      idCloudUrl: env.urls.core.id,
      external_api_url: env.urls.core.external,
    });
  }

  return client;
}
