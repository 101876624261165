import revive_payload_client_tO9ZelaJwE from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.3_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_cegceq4jqas5xvrpeyogx453pi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wkgjS2oX3v from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.3_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_cegceq4jqas5xvrpeyogx453pi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wnRSaVnKdD from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.3_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_cegceq4jqas5xvrpeyogx453pi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RCWUPwlZrQ from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.27.4_vite@5.4.11_@types+node@22.9.3_sass@1.81_l6nlqsbi52dv7iho2xsx4hcqxi/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_GSJZNIBoWD from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.3_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_cegceq4jqas5xvrpeyogx453pi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FtdYuLnMyS from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.3_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_cegceq4jqas5xvrpeyogx453pi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nKuLcuHGID from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.3_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_cegceq4jqas5xvrpeyogx453pi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GdnTIFWtGC from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.3_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_cegceq4jqas5xvrpeyogx453pi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_XBXfJxWS7p from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.4_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_mrl4Sx88ph from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.9.3_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_cegceq4jqas5xvrpeyogx453pi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_hyl59kpEwY from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt-auth-utils@0.5.5_magicast@0.3.5_rollup@4.27.4/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import plugin_l2o28sWCek from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt-graphql-client@0.2.41_@parcel+watcher@2.5.0_@types+node@22.9.3_graphql-tag@2.12.6_graphq_idby5j2wqn6owuvgrg2nvx3wgm/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import datadog_client_cfAAU11B0P from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/datadog.client.ts";
import ks_toast_client_iF5eIzTVpP from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/ks-toast.client.ts";
import error_handler_kEP5FliEXj from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/error-handler.ts";
import vue_masonry_wall_13R6vu58Yn from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/vue-masonry-wall.ts";
import vue_query_wrmMkNpEpe from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/vue-query.ts";
export default [
  revive_payload_client_tO9ZelaJwE,
  unhead_wkgjS2oX3v,
  router_wnRSaVnKdD,
  _0_siteConfig_RCWUPwlZrQ,
  payload_client_GSJZNIBoWD,
  navigation_repaint_client_FtdYuLnMyS,
  check_outdated_build_client_nKuLcuHGID,
  chunk_reload_client_GdnTIFWtGC,
  plugin_vue3_XBXfJxWS7p,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mrl4Sx88ph,
  session_client_hyl59kpEwY,
  plugin_l2o28sWCek,
  datadog_client_cfAAU11B0P,
  ks_toast_client_iF5eIzTVpP,
  error_handler_kEP5FliEXj,
  vue_masonry_wall_13R6vu58Yn,
  vue_query_wrmMkNpEpe
]