/**
 * Returns the total number of students in the selected organization, or 1 if the number is not available.
 */
export function useTotStudents() {
  const { data } = useSelectedOrganization();

  return computed(
    () => data.value?.numberOfPupilsOverride || data.value?.numberOfPupils || 1,
  );
}
