<script setup lang="ts">
const jumpToContent = ref();

function unFocusEL() {
  jumpToContent.value?.blur();
}

onMounted(() => {
  jumpToContent.value = document.getElementById("skip-to-content-link")!;
});
</script>

<template>
  <NuxtLink
    id="skip-to-content-link"
    to="#main-content"
    class="skip-to"
    @keyup.enter.prevent="unFocusEL"
    @keyup.space.prevent="unFocusEL"
    @click.prevent="unFocusEL"
  >
    Hopp til hovedinnhold
  </NuxtLink>
</template>

<style scoped lang="scss">
.skip-to {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100vw;
  min-height: 6rem;
  background-color: $seagreen-40;
  text-align: center;
  position: absolute;
  top: -24rem;
  z-index: 1000;
  font-size: 1.2rem;
  border: none;
  color: $white;

  &:focus {
    top: 0;
  }
}
</style>
