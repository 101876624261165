import { VersionLabel } from "#gql/default";

export default function useCrystallizeVersion(route = useRoute()) {
  // The options provided to usePreviewMode here are exactly equal to Nuxt's default implementation, except for the fact that useRoute is called internally by the default options. We want to pass our own route, so that we can use this composable in middleware.
  const previewMode = usePreviewMode({
    shouldEnable: () => route.query.preview === "true",
    getState: (state) => {
      if (state.token !== undefined) {
        return state;
      }

      state.token = Array.isArray(route.query.token)
        ? route.query.token[0]
        : route.query.token;

      return state;
    },
  });

  return previewMode.enabled.value
    ? VersionLabel.Draft
    : VersionLabel.Published;
}
