export const ARTICLE_CARD_COLUMNS = [2, 3, 4] as const;

export type ColumnsArticlesType = (typeof ARTICLE_CARD_COLUMNS)[number];

export type ImageSizeVariantsType =
  | 100
  | 200
  | 500
  | 768
  | 1024
  | 1366
  | 1600
  | 1920
  | 3200;
