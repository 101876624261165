export function flatten<T>(array: Array<T | T[] | undefined | null>): T[] {
  return array.filter(nonNullable).flatMap((it) => it);
}

const OPTIONS = [...LEVEL_OPTIONS, ...SUBJECT_OPTIONS, ...THEME_OPTIONS];

const ALL_LEVELS = ["1-7", "8-10", "vgs"];

export function getTagNamesFromValues(
  ...args: (string | string[] | undefined)[]
) {
  let tags = flatten(args);

  // TODO: This doesn't play well with voksenopplæring and student
  if (ALL_LEVELS.every((it) => tags.includes(it))) {
    tags = tags.filter((it) => !ALL_LEVELS.includes(it));
  }

  return tags
    .map((tag) => OPTIONS.find((option) => option.value === tag)?.tagName)
    .filter(nonNullable);
}
